import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { addAppProject } from "../../apiservice/appService";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import "./App-Comp.css";

type IProjectFormCreate = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const ProjectFormCreate: React.FC<IProjectFormCreate> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => addAppProject(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      alert("Project Created Succesfully");
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Job Title */}

          <div className="grid mt-4">
            <div>
              <span>
                Project Title <span className="w3-text-red">*</span>
              </span>
            </div>
            <input
              required
              name="title"
              value={payLoad?.title}
              onChange={handleInputChange}
              type="text"
              className="w3-col w3-input w3-round w3-border"
              placeholder="Project Title"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* Button Here */}
          <div className="flex item-center justify-end mt-4">
            <button
              className="h-12 rounded bg-green text-white p-2 px-5"
              disabled={formLoading}
            >
              {!formLoading ? "Proceed" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </>
    </div>
  );
};

export default ProjectFormCreate;
