import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";
import { IAuthType } from "./authService.type";

export const sampleApiCall = async (body?: any, id?: any) => {
  const axios = await instance("", "https://dummyjson.com/", true);
  const { data } = await axios.get("/todos");
  const result: IAuthType = await data;
  return result;
};

// Projects
export const addAppProject = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/v1/projects", body);
  const result: IAuthType = await data;
  return result;
};

export const getAppProjects = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/projects${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

// End Points
export const addAppEndpoints = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/v1/endpoints", body);
  const result: IAuthType = await data;
  return result;
};

export const getAppEndpoints = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/endpoints${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

//Get Mock
export const getMock = async (id: number | string, body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/mocks/${id}`, body);
  const result: IAuthType = await data;
  return result;
};
