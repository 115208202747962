import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAppProjects } from "../../apiservice/appService";
import { AppProjectData } from "../../apiservice/appService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import { useAppDispatch } from "../../Redux/reduxCustomHook";
import { ILoadState } from "../../utils/loading.utils.";
import "./App-Comp.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IProjectList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const ProjectList: React.FC<IProjectList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [projectsLoadState, setProjectsLoadState] =
    useState<ILoadState>("loading");
  const [loadprojectsData, setLoadProjectsData] = useState(true);
  const [projectsDefaultFilter, setProjectsDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<AppProjectData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // For Navigator/Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setProjectsDefaultFilter({
        ...projectsDefaultFilter,
        ...externalFilter,
      });
      setLoadProjectsData(true);
      setProjectsLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All projects Details
  const projectsDataResult = useFormatApiRequest(
    () => getAppProjects(projectsDefaultFilter),
    loadprojectsData,
    () => {
      setLoadProjectsData(false);
    },
    () => {
      processprojectsResult();
    }
  );

  // Process The Current Projects Data Result
  const processprojectsResult = async () => {
    if (projectsDataResult.httpState === "SUCCESS") {
      setTableData(projectsDataResult.data?.data);
      setProjectsLoadState("completed");
      setTotalItems(projectsDataResult.data?.meta?.total || 1);
    } else if (projectsDataResult.httpState === "ERROR") {
      setProjectsLoadState("error");
    } else if (projectsDataResult.httpState === "LOADING") {
      setProjectsLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setProjectsDefaultFilter({
      ...projectsDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadProjectsData(true);
  };

  // Navigate to the next Page
  const navigateForward = async (index: number) => {
    // dispatch({
    //   type: "TENANT_ADD_SELECTED_Projects",
    //   payload: tableData[index],
    // });
    // navigate(`/explore/explore-professionals/1/calender`);
  };

  const deleteProject = async (index: number) => {
    alert("Delete Not Active");
  };
  const editProject = async (index: number) => {
    alert("Edit Not Active");
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="grid pt-4">
        {/* " Show Loading Indicator" */}
        {projectsLoadState === "loading" && (
          <div className="mt-2 mb-2 flex justify-center items-center pt-20 pb-20">
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {projectsLoadState === "error" && (
          <div className="mt-2 mb-2">
            <Result
              status="500"
              title={<span className="">Error</span>}
              subTitle={
                <span className="">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadProjectsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {projectsLoadState === "noData" && (
          <div className="mt-2">
            <Empty></Empty>
          </div>
        )}

        {/* " Show Data " */}
        {projectsLoadState === "completed" && (
          <div className="mt-2">
            <div>
              {/* Projects */}

              <div className="grid grid-cols-1 gap-4">
                {tableData.map((projects, index) => (
                  <>
                    <div key={index}>
                      {/* Cards */}
                      <div className="flex flex-row p-2 w-full bg-white border border-gray-200 rounded-xl">
                        <div className="basis-3/4 p-2 flex items-center justify-start">
                          <h2 className="font-semibold font-sans text-md mb-2">
                            {projects.title}
                          </h2>
                        </div>

                        <div className="basis-1/4 p-2 flex items-center justify-end">
                          <button
                            onClick={() => {
                              editProject(index);
                            }}
                            className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-green bg-white border border-green hover:bg-green-100"
                          >
                            <EditOutlined />
                          </button>
                          <button
                            onClick={() => {
                              deleteProject(index);
                            }}
                            className="font-medium rounded-lg text-xs px-5 py-2.5 me-2 mb-2 text-red-500  bg-white border border-red-500 hover:bg-red-300"
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </div>
                      {/* End  Cards */}
                    </div>
                  </>
                ))}
              </div>

              {/* End Projects */}
            </div>

            {!hidePagination && (
              <div className="grid mt-2">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
