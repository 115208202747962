import { useState } from "react";
import { Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IAuthType } from "../../apiservice/authService.type";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { appZIndex } from "../../utils/appconst";
import EndPointCreateForm from "./endpointFormCreate";
import "./App-Comp.css";
import { EndpointList } from "./endpointsList";
import { getAppProjects } from "../../apiservice/appService";
import { AppProjectData } from "../../apiservice/appService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";

export const EndpointWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);

  const [loadProjectsApi, setLoadProjectsApi] = useState(true);
  const [projectsData, setProjectsData] = useState<AppProjectData[]>([]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // A custom hook to Load Projects from the server
  const projectsResult = useFormatApiRequest(
    () => getAppProjects(),
    loadProjectsApi,
    () => {
      setLoadProjectsApi(loadProjectsApi);
    },
    () => {
      processProjectsResultApi();
    }
  );

  // Process Project Result
  const processProjectsResultApi = async () => {
    if (projectsResult.httpState === "SUCCESS") {
      setProjectsData(projectsResult.data?.data);
    } else if (projectsResult.httpState === "ERROR") {
      // console.log(projectsResult.errorMsg);
    }
  };

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="grid p-4">
        {/* Title */}
        <div className="grid grid-cols-2">
          <div className="flex" style={{ padding: "5px", paddingTop: "15px" }}>
            <h2 className="font-sans text-2xl font-semibold"> EndPoints </h2>
          </div>

          <div className="flex items-center justify-end">
            <button
              onClick={handleShowModal}
              className="sitePrimaryButton w3-btn w3-round-large"
            >
              Create New <PlusCircleOutlined />
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-row mt-6 gap-2">
          <div className="basis-7/12">
            <div>
              <input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="h-12 w-full border rounded-xl p-2"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="basis-5/12">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <select
                  name="projectId"
                  value={payLoadFilter?.projectId || "-1"}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="h-12 w-full border rounded-xl p-2"
                >
                  <option value="">Select Project</option>
                  {projectsData.map((projects, index) => (
                    <option key={index} value={projects.id}>
                      {projects.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <button className="h-12 w-full border rounded-xl p-2 border-green text-green">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Table/List/Grid */}
        <div className="grid mt-4">
          <EndpointList></EndpointList>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Create New Mock Endpoint"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "350px", overflow: "scroll" }}>
          <EndPointCreateForm></EndPointCreateForm>
        </div>
      </Modal>
    </>
  );
};

export default EndpointWrapper;
