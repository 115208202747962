import EndpointWrapper from "../../../components/usersApiComps/endpointListWrapper";
import "../user.css";

export const UserEndPoints = () => {
  return (
    <>
      <EndpointWrapper></EndpointWrapper>
    </>
  );
};

export default UserEndPoints;
