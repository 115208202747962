import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { addAppEndpoints, getAppProjects } from "../../apiservice/appService";
import { AppProjectData } from "../../apiservice/appService.type";
import useFormatApiRequest from "../../hooks/formatApiRequest";
import "./App-Comp.css";

type IEndPointCreateForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const EndPointCreateForm: React.FC<IEndPointCreateForm> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formattedPayLoad, setFormattedPayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  const [loadProjectsApi, setLoadProjectsApi] = useState(true);
  const [projectsData, setProjectsData] = useState<AppProjectData[]>([]);

  // Use to collect input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
    setFormattedPayLoad({
      projectId: payLoad?.projectId,
      title: payLoad?.title,
      request: {
        method: payLoad?.requestMethod,
        body: convertStringToJson(payLoad?.requestBody)
          ? convertStringToJson(payLoad?.requestBody)
          : {},
        Headers: convertStringToJson(payLoad?.requestHeader)
          ? convertStringToJson(payLoad?.requestHeader)
          : [],
      },
      response: {
        body: convertStringToJson(payLoad?.responseBody)
          ? convertStringToJson(payLoad?.responseBody)
          : {},
      },
    });
  };

  // const handleSelectChange = (value, name) => {
  //   console.log(value, name);
  //   setpayLoad((values) => ({ ...values, [name]: value }));
  // };

  // A custom hook to Load Projects from the server
  const projectsResult = useFormatApiRequest(
    () => getAppProjects(),
    loadProjectsApi,
    () => {
      setLoadProjectsApi(loadProjectsApi);
    },
    () => {
      processProjectsResultApi();
    }
  );

  // Process Project Result
  const processProjectsResultApi = async () => {
    if (projectsResult.httpState === "SUCCESS") {
      setProjectsData(projectsResult.data?.data);
    } else if (projectsResult.httpState === "ERROR") {
      // console.log(projectsResult.errorMsg);
    }
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
    // console.log(payLoad);
    // console.log(formattedPayLoad);
  };

  const convertStringToJson = (data: string) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  };

  const convertJsonToString = (data: JSON) => {
    try {
      return JSON.stringify(data);
    } catch (error) {
      return null;
    }
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => addAppEndpoints(formattedPayLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      alert("End Point Created Succesfully");
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <div className="grid mt-4">
            <div>
              <span>
                End Point Title <span className="w3-text-red">*</span>
              </span>
            </div>
            <input
              required
              name="title"
              value={payLoad?.title}
              onChange={handleInputChange}
              type="text"
              className="h-12 w-full border rounded-xl p-2"
              placeholder=" End Point Title"
              style={{ width: "100%", height: "50px" }}
            />
          </div>

          {/* Project ID */}
          <div className="grid mt-4">
            <div>
              <span>
                Project<span className="w3-text-red">*</span>
              </span>
            </div>
            <select
              name="projectId"
              value={payLoad?.projectId || ""}
              onChange={handleInputChange}
              required
              className="w-full border rounded-xl p-2"
              style={{ width: "100%", height: "50px" }}
            >
              <option value="">Select Project</option>
              {projectsData.map((projects, index) => (
                <option key={index} value={projects.id}>
                  {projects.title}
                </option>
              ))}
            </select>
          </div>

          {/* Request Method */}
          <div className="grid mt-4">
            <div>
              <span>
                Request Method <span className="w3-text-red">*</span>
              </span>
            </div>
            <select
              required
              name="requestMethod"
              value={payLoad?.requestMethod || ""}
              onChange={handleInputChange}
              className="h-12 w-full border rounded-xl p-2"
              style={{ width: "100%", height: "50px" }}
            >
              <option value="">Request Method </option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
              <option value="PATCH">PATCH</option>
            </select>
          </div>

          {/* Request Body */}
          <div className="grid mt-4">
            <div>
              <span>Request Body</span>
            </div>
            <textarea
              name="requestBody"
              value={payLoad?.requestBody}
              onChange={handleInputChange}
              className="h-12 w-full border rounded-xl p-2"
              placeholder={`Body of Request (Optional) e.g { "id": 1}`}
              style={{ width: "100%", height: "80px", resize: "none" }}
            />
          </div>

          {/* Request Header */}
          <div className="grid mt-4">
            <div>
              <span>Request Header</span>
            </div>
            <textarea
              name="requestHeader"
              value={payLoad?.requestHeader}
              onChange={handleInputChange}
              className="h-12 w-full border rounded-xl p-2"
              placeholder={`Body of Request (Optional) e.g [ { "key": "key1",   "value": "value1  }]`}
              style={{ width: "100%", height: "80px", resize: "none" }}
            />
          </div>

          {/* Respose Body */}
          <div className="grid mt-4">
            <div>
              <span>Response Body</span>
            </div>
            <textarea
              required
              name="responseBody"
              value={payLoad?.responseBody}
              onChange={handleInputChange}
              className="h-12 w-full border rounded-xl p-2"
              placeholder={`Body of Response (Optional) e.g { "id": 1}`}
              style={{ width: "100%", height: "80px", resize: "none" }}
            />
          </div>

          {/* Button Here */}
          <div className="flex item-center justify-end mt-4">
            <button
              className="h-12 rounded bg-green text-white p-2 px-5"
              disabled={formLoading}
            >
              {!formLoading ? "Proceed" : <LoadingOutlined rev={undefined} />}
            </button>
          </div>
        </form>
      </>
    </div>
  );
};

export default EndPointCreateForm;
