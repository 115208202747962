import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Nopage from "./pages/Nopage/Nopage";
import UsersPageRoutes from "./pages/userDashboard/userPagesRoute";

const App = () => {
  const authState = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<UsersPageRoutes />} />
        <Route path="app/*" element={<UsersPageRoutes></UsersPageRoutes>} />
        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
