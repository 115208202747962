import ProjectWrapper from "../../../components/usersApiComps/projectListWrapper";
import "../user.css";

export const UserHome = () => {
  return (
    <>
      <ProjectWrapper></ProjectWrapper>
    </>
  );
};

export default UserHome;
