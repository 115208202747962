import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import UserLogout from "./UserLogout/UserLogout";
import UserLayout from "./UserLayout/UserLayout";
import UserHome from "./UserHome/UserHome";
import UserEndPoints from "./UserEndPoints/UserEndPoints";

const UsersPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route index element={<UserHome />} />
        <Route path="/endpoints" element={<UserEndPoints />} />
        <Route path="/logout" element={<UserLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default UsersPageRoutes;
