import { useState } from "react";
import { Input, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IAuthType } from "../../apiservice/authService.type";
import { useAppSelector } from "../../Redux/reduxCustomHook";
import { RootState } from "../../Redux/store";
import { appZIndex } from "../../utils/appconst";
import ProjectFormCreate from "./projectFormCreate";
import { ProjectList } from "./projectsList";
import "./App-Comp.css";

export const ProjectWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="grid p-4">
        {/* Title */}
        <div className="grid grid-cols-2">
          <div className="flex" style={{ padding: "5px", paddingTop: "15px" }}>
            <h2 className="font-sans text-2xl font-semibold"> Projects </h2>
          </div>

          <div className="flex items-center justify-end">
            <button
              onClick={handleShowModal}
              className="sitePrimaryButton w3-btn w3-round-large"
            >
              Create New <PlusCircleOutlined />
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-row mt-6 gap-2">
          <div className="basis-10/12">
            <div>
              <input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="h-12 w-full border rounded-xl p-2"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="basis-2/12">
            <div>
              <button className="h-12 w-full border rounded-xl p-2 border-green text-green">
                Search
              </button>
            </div>
          </div>
        </div>

        {/* Table/List/Grid */}
        <div className="grid mt-4">
          <ProjectList></ProjectList>
        </div>
      </div>

      {/* Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showModal}
        title={"Create New Project"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
          <ProjectFormCreate></ProjectFormCreate>
        </div>
      </Modal>
    </>
  );
};

export default ProjectWrapper;
